<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="space-around">
      <v-col cols="12" sm="12" md="4">
        <v-row>
          <v-col align="center">
            <h2>Obra Social</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input accept=".txt" v-model="form.cds" placeholder="Archivo Obra Social" label="Archivo CDS">
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input accept=".txt" v-model="form.ordenes" placeholder="Archivo Municipalidad"
              label="Archivo Órdenes Descontadas"></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-btn elevation="2" color="primary" :disabled="loading" :loading="loading" @click="upload">Aceptar</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        form: {
          cds: undefined,
          ordenes: undefined
        } 
      }
    },

    methods: {
      async upload() {
        if (!this.form.cds) return this.$toast.push("info", "Por favor seleccione un archivo de obra social");
        if (!this.form.ordenes) return this.$toast.push("info", "Por favor seleccione un archivo de ordenes descontadas");
        this.loading = true;
        await this.$api.sendForm("obra-social", this.form);
        this.loading = false;
      }
    }
  }
</script>